import Utils from "../../utils";

import eventBus from '../../utils/eventBus';
export default class WMSHeader {
    private wmsUIEvents = eventBus.channel('wms_ui')

    private warehouseSelect: HTMLSelectElement
    private warehouses: any = {}

    private timeUpdateInterval: string | number | NodeJS.Timeout | undefined;

    constructor() {
        this.warehouseSelect = document.querySelector('#wms_header_warehouses') as HTMLSelectElement;
        this.init();
    }

    async init() {
        await this.addEventListeners()
        if (document.querySelector("#wms_header_existing_transactions")) {
            await this.loadWarehouses()
            await this.buildWarehouseSelect()
            await this.loadExistingTransactions()
        } else {
            await this.setCurrentPickingInfo()
            this.bindUiEvents();
        }
    }

    bindUiEvents() {
        this.wmsUIEvents.subscribe("item_picked", async () => {
            await this.setCurrentPickingInfo(false);
        })
    }

    async setCurrentPickingInfo(loader = true) {
        const pickingId = document.querySelector("div.row[data-picking-id]")?.getAttribute("data-picking-id");
        if (pickingId) {
            const request = await Utils.entity.get(pickingId, 'wms/transactions', null, null, !loader)
            if (request.status === 200) {
                const data = request.data[0];
                //(document.querySelector("#wms_header_picking_start") as HTMLElement).innerHTML = `${new Date(Date.parse(data.created_at)).toLocaleString()}`;
                //(document.querySelector("#wms_header_picking_products_total") as HTMLElement).innerHTML = `${data.items.length}`;
                (document.querySelector("#wms_header_picking_products_done") as HTMLElement).innerHTML = `${data.items.filter((i: { picked: any; }) => i.picked).length}`;
                (document.querySelector("#wms_header_picking_products_remaining") as HTMLElement).innerHTML = `${data.items.filter((i: { picked: any; }) => !i.picked).length}`;
                const updateDuration = () => {
                    const startDate = new Date(Date.parse(data.created_at));
                    const endDate = new Date();
                    const diffSeconds = (endDate.getTime() - startDate.getTime()) / 1000;
                    (document.querySelector("#wms_header_picking_spent_time") as HTMLElement).innerHTML =`${Utils.secondsToHhMmSs(diffSeconds)}`;
                }
                if (this.timeUpdateInterval) {
                    clearInterval(this.timeUpdateInterval)
                }
                this.timeUpdateInterval = setInterval(() => {
                    updateDuration();
                }, 1000);
                updateDuration();
            }
        }
    }

    async addEventListeners() {
        if (this.warehouseSelect) {
            this.warehouseSelect.addEventListener("change", (e) => {
                e.preventDefault()
                const id = (this.warehouseSelect.querySelector('option:checked') as HTMLOptionElement).value
                localStorage.setItem('tickware_wms_warehouse', id)
                document.location.reload()
            });
            const existingContinueButton = document.querySelector("#wms_header_existing_transactions_continue")
            if (existingContinueButton) {
                existingContinueButton.addEventListener("click", (e) => {
                    e.preventDefault();
                    const newIdOption = document.querySelector("#wms_header_existing_transactions option:checked") as HTMLOptionElement | null
                    if (newIdOption) {
                        const value = newIdOption.value;
                        document.location.href = `/${(window as any).currentLocale}/wms/picking/${value}`
                    }
                })
            }
        }

    }

    async loadExistingTransactions() {
        const existingSelect = document.querySelector("#wms_header_existing_transactions")
        const existingContinueButton = document.querySelector("#wms_header_existing_transactions_continue")
        const currentWarehouseId = localStorage.getItem('tickware_wms_warehouse') as string
        if (existingSelect && existingContinueButton) {
            const dataRequest = await Utils.entity.getAll('wms/transactions')
            if (dataRequest.status === 200) {
                const data = dataRequest.data.filter((d: { status: string; warehouse_uuid: string; }) => d.status === "PENDING" && d.warehouse_uuid === currentWarehouseId)
                if (data.length > 0) {
                    let html = ''
                    data.forEach((transaction: { uuid: any; warehouse_uuid: string; created_at: string; items: string | any[]; }) => {
                        html += `<option value="${transaction.uuid}">${new Date(Date.parse(transaction.created_at)).toLocaleString()} - ${transaction.items.length} ${Utils.translate('erp.product.names')}</option>`
                    })
                    existingSelect.innerHTML = html;
                    existingSelect.classList.remove("d-none");
                    existingContinueButton.classList.remove("d-none");
                    existingContinueButton.removeAttribute("disabled");
                }
            }
        }
    }

    async loadWarehouses() {
        const warehouseData = await Utils.entity.getAll('erp/warehouses')
        const warehouses = warehouseData.data
        warehouses.forEach((warehouse: { id: string | number; name: any; }) => {
            this.warehouses[warehouse.id] = warehouse.name;
        })
    }

    async buildWarehouseSelect() {
        let html = ''
        const selectedWarehouse = localStorage.getItem('tickware_wms_warehouse')
        if (!selectedWarehouse) {
            html += `<option selected disabled>${Utils.translate('wms.header.choose_warehouse')}</option>`
        }
        Object.keys(this.warehouses).forEach((warehouseId) => {
            const warehouseName = this.warehouses[warehouseId]
            html += `<option value="${warehouseId}" ${selectedWarehouse === warehouseId ? 'selected' : ''}>${warehouseName}</option>`
        })
        this.warehouseSelect.innerHTML = html;
    }
}